import React from 'react'
import Helmet from 'react-helmet'
import { Layout, BannerSmall, BannerLinks } from '../components'

import BannerImage from '../assets/balance.jpg'
import holzwegTeamL from '../assets/bannerlinks/team.holzweg.l.jpg'
import holzwegTeamR from '../assets/bannerlinks/team.holzweg.r.jpg'

const boxes = [
  {
    text: "Let's do it!",
    image: holzwegTeamL,
    link: '/kontakt',
    linkText: 'Kontakt'
  },
  {
    text: 'Join the team!',
    image: holzwegTeamR,
    link: '/karriere',
    linkText: 'Mehr lesen'
  }
]

const Jobs = () => (
  <Layout>
  <Helmet
    title="Jobs"
    meta={[
      {
        name: 'description',
        content:
          'Wir legen Wert auf motivierte Kollegen, eigene Ideen und Kreativität. Damit deine Freizeit nicht zu kurz kommt, bieten wir flexible Arbeitszeiten. Willst du in einem netten Team, einer familiären Atmosphäre arbeiten?'
      },
      {
        name: 'keywords',
        content:
          'Karriere holzweg, Webagentur holzweg, Karriere, Job, Jobs Tirol, Jobs Innsbruck, Jobs Programmierer Tirol'
      },
      {
        property: 'og:description',
        content:
          'Wir legen Wert auf motivierte Kollegen, eigene Ideen und Kreativität. Damit deine Freizeit nicht zu kurz kommt, bieten wir flexible Arbeitszeiten. Willst du in einem netten Team, einer familiären Atmosphäre arbeiten?'
      },
      {
        property: 'og:image',
        content: 'https://www.holzweg.com/static/balance-dac2dcf9574621700be220484269619e.jpg'
      }
    ]}
  />
    <div>
      <BannerSmall image={BannerImage} title="Gemeinsam statt einsam" />
      <BannerLinks boxes={boxes} />
    </div>
  </Layout>
)

export default Jobs
